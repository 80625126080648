import React from 'react'
import { Link } from 'gatsby'
import { FaVideo, FaBook, FaChalkboardTeacher, FaCode, FaMusic, FaPuzzlePiece} from 'react-icons/fa'
import styled from 'styled-components'

const Wrapper = styled.header`
  align-items: center;
  background-color: ${props => props.theme.colors.header_bg_color};
  display: flex;
  padding: 0.5rem 0 0.5rem 0;
  position: relative;
  z-index: 1000;
  a {
    color: ${props => props.theme.colors.header_color};
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    z-index: 100;
    &:hover {
      color: ${props => props.theme.brand.secondary};
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 1rem 0 1rem 0;
    flex-wrap: wrap;
  }
`

const Category = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  a {
    font-size: 0.8rem;
    font-family: 'Merriweather', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
    font-weight: 700;
    &:hover,
    &:focus {
      color: ${props => props.theme.brand.secondary};
      text-decoration: none;
    }
  }
  a.selected {
    color: ${props => props.theme.brand.secondary};
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    order: 1;
    flex: 1 0 100%;
    margin-bottom: 0.75rem;
  }
`

const CategoryButton = ({ id, name, icon, pathname }) => (
  <Category>
    <Link to={`/${id}/`} className={pathname.includes(`/${id}`) ? 'selected' : ''}>
      {icon} {name}
    </Link>
  </Category>
)

const CategoryNav = ({ pathname }) => (
  <Wrapper data-testid="category-nav">
    <CategoryButton
      id={'programming'}
      name={'プログラム'}
      icon={<FaCode />}
      pathname={pathname}
    />
    <CategoryButton
      id={'presentation'}
      name={'プレゼン'}
      icon={<FaChalkboardTeacher />}
      pathname={pathname}
    />
    <CategoryButton
      id={'book'}
      name={'同人誌'}
      icon={<FaBook />}
      pathname={pathname}
    />
    <CategoryButton
      id={'movie'}
      name={'動画'}
      icon={<FaVideo />}
      pathname={pathname}
    />
    <CategoryButton
      id={'music'}
      name={'音楽'}
      icon={<FaMusic />}
      pathname={pathname}
    />
    <CategoryButton
      id={'escape-game'}
      name={'謎解き'}
      icon={<FaPuzzlePiece />}
      pathname={pathname}
    />
  </Wrapper>
)

export default CategoryNav
