module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: '黒曜堂', // Navigation and Site Title
  siteTitleAlt: "黒曜堂 - kokuyouwind's Portfolio", // Alternative Site title for SEO
  siteHeadline: '黒曜(@kokuyouwind)のポートフォリオサイト', // Headline for schema.org JSONLD
  siteTitleShort: '黒曜堂', // short_name for manifest
  siteUrl: 'https://www.kokuyouwind.com/', // Domain of your site. No trailing slash!
  siteLanguage: 'ja', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: '黒曜(@kokuyouwind)のポートフォリオサイトです',
  author: 'kokuyouwind', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@kokuyouwind', // Twitter Username
  ogSiteName: '黒曜道', // Facebook Site Name
  ogLanguage: 'ja_JP',
  googleAnalyticsID: 'UA-51346848-3',

  // Manifest and Progress color
  themeColor: '#1d4ab3',
  backgroundColor: '#f3f3f3',
}
